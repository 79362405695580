const blogPosts = [
  {
    slug: "introduction-to-probability",
    title: "Introduction to Probability",
    date: "2023-04-01",
    excerpt: "Learn the basics of probability and its importance in various fields."
  },
  {
    slug: "understanding-conditional-probability",
    title: "Understanding Conditional Probability",
    date: "2023-04-15",
    excerpt: "Explore the concept of conditional probability and its applications."
  },
  // Add more blog posts here
];

export default blogPosts;