import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import 'katex/dist/katex.min.css';

function BlogPost() {
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    import(`../data/blogContents/${slug}.js`)
      .then(module => {
        const { content } = module;
        const frontmatterRegex = /^---\s*\n([\s\S]*?)\n---\s*\n/;
        const match = content.trim().match(frontmatterRegex);
        
        if (!match) {
          throw new Error('Invalid Markdown format: No frontmatter found');
        }

        const [, frontmatter] = match;
        const postContent = content.trim().replace(frontmatterRegex, '').trim();

        const metadata = frontmatter.split('\n').reduce((acc, line) => {
          const [key, ...valueParts] = line.split(':');
          if (key && valueParts.length > 0) {
            acc[key.trim()] = valueParts.join(':').trim();
          }
          return acc;
        }, {});

        if (!metadata.title || !metadata.date) {
          throw new Error('Invalid frontmatter: Missing required fields');
        }

        setPost({ ...metadata, content: postContent });
      })
      .catch(err => {
        console.error('Error loading blog post:', err);
        setError(`Failed to load blog post: ${err.message}`);
      });
  }, [slug]);

  if (error) return <div className="text-red-500 text-center py-8">{error}</div>;
  if (!post) return <div className="text-dark-text-secondary text-center py-8">Loading...</div>;

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <article className="bg-dark-surface shadow-lg rounded-lg overflow-hidden">
        <header className="bg-gradient-to-r from-blue-600 to-blue-800 py-8 px-6">
          <h1 className="text-4xl font-bold text-white mb-2">{post.title}</h1>
          <p className="text-blue-200">Published on: {post.date}</p>
        </header>
        <div className="p-6">
          <div className="prose prose-lg prose-invert max-w-none">
            <ReactMarkdown 
              rehypePlugins={[rehypeKatex]}
              remarkPlugins={[remarkMath]}
              components={{
                h2: ({node, ...props}) => <h2 className="text-2xl font-semibold text-dark-primary mt-8 mb-4" {...props} />,
                h3: ({node, ...props}) => <h3 className="text-xl font-semibold text-dark-primary mt-6 mb-3" {...props} />,
                p: ({node, ...props}) => <p className="text-dark-text mb-4" {...props} />,
                ul: ({node, ...props}) => <ul className="list-disc list-inside mb-4" {...props} />,
                ol: ({node, ...props}) => <ol className="list-decimal list-inside mb-4" {...props} />,
                li: ({node, ...props}) => <li className="text-dark-text mb-2" {...props} />,
                blockquote: ({node, ...props}) => <blockquote className="border-l-4 border-blue-500 pl-4 italic my-4" {...props} />,
                code: ({node, inline, ...props}) => 
                  inline 
                    ? <code className="bg-gray-800 text-blue-300 px-1 rounded" {...props} />
                    : <pre className="bg-gray-800 text-blue-300 p-4 rounded-lg overflow-x-auto my-4" {...props} />,
              }}
            >
              {post.content}
            </ReactMarkdown>
          </div>
        </div>
      </article>
      <div className="mt-8 text-center">
        <Link to="/blog" className="inline-block bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition-colors duration-200">
          &larr; Back to Blog
        </Link>
      </div>
    </div>
  );
}

export default BlogPost;