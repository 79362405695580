import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'katex/dist/katex.min.css';
import { Analytics } from "@vercel/analytics/react";
import { PostHogProvider } from 'posthog-js/react'
import posthog from 'posthog-js'

// Initialize PostHog
posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
  // Enable debug mode in development
  loaded: (posthog) => {
    if (process.env.NODE_ENV === 'development') posthog.debug()
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <App />
      <Analytics />
    </PostHogProvider>
  </React.StrictMode>
);