import React from 'react';

function AboutPage() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-dark-primary mb-6">About Probability Calculators</h1>
      <div className="bg-dark-surface shadow-md rounded-lg p-6 space-y-6">
        <section>
          <h2 className="text-2xl font-semibold text-dark-text mb-3">Our Mission</h2>
          <p className="text-dark-text-secondary leading-relaxed">
            Welcome to Probability Calculators, your go-to resource for understanding and calculating various probability concepts. Our mission is to make probability calculations accessible and easy to understand for students, professionals, and enthusiasts alike.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-dark-text mb-3">What We Offer</h2>
          <ul className="list-disc list-inside text-dark-text-secondary space-y-2">
            <li>User-friendly calculators for various probability concepts</li>
            <li>Detailed explanations and examples for each calculator</li>
            <li>A blog with in-depth articles on probability theory and its applications</li>
            <li>Regularly updated content to cover more topics and improve existing calculators</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-dark-text mb-3">Who We Serve</h2>
          <p className="text-dark-text-secondary leading-relaxed">
            Whether you're a student studying for an exam, a professional applying probability in your work, or simply curious about the mathematics of chance, we hope you find our tools and resources valuable.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-dark-text mb-3">Get in Touch</h2>
          <p className="text-dark-text-secondary leading-relaxed">
            Thank you for using Probability Calculators. If you have any questions or suggestions, please don't hesitate to contact us at <a href="mailto:contact@probabilitycalculators.com" className="text-dark-primary hover:underline">contact@probabilitycalculators.com</a>.
          </p>
        </section>
      </div>
    </div>
  );
}

export default AboutPage;