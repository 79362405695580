import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Analytics } from "@vercel/analytics/react"

function Layout({ children }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="min-h-screen bg-dark-background">
      <header className="bg-dark-surface text-dark-text shadow-sm">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <Link to="/" className="text-2xl font-bold text-dark-primary">Probability Calculators</Link>
          <div className="md:hidden">
            <button onClick={toggleMenu} className="text-dark-text focus:outline-none">
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
          <nav className={`md:flex ${isMenuOpen ? 'block' : 'hidden'} absolute md:relative top-16 md:top-0 left-0 md:left-auto w-full md:w-auto bg-dark-surface md:bg-transparent`}>
            <ul className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 p-4 md:p-0">
              <li><Link to="/" className="block md:inline-block hover:text-dark-primary" onClick={() => setIsMenuOpen(false)}>Home</Link></li>
              <li><Link to="/blog" className="block md:inline-block hover:text-dark-primary" onClick={() => setIsMenuOpen(false)}>Blog</Link></li>
              <li><Link to="/about" className="block md:inline-block hover:text-dark-primary" onClick={() => setIsMenuOpen(false)}>About</Link></li>
            </ul>
          </nav>
        </div>
      </header>
      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        {children}
        <Analytics />
      </main>
    </div>
  );
}

export default Layout;