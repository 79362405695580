// src/data/calculators.js

import React from 'react';
import { lazy } from 'react';

const SingleEventProbabilityCalculator = React.lazy(() =>
  import('../components/SingleEventProbabilityCalculator')
);
const CombinedProbabilityCalculator = React.lazy(() =>
  import('../components/CombinedProbabilityCalculator')
);
const ConditionalProbabilityCalculator = React.lazy(() =>
  import('../components/ConditionalProbabilityCalculator')
);
const BinomialProbabilityCalculator = React.lazy(() =>
  import('../components/BinomialProbabilityCalculator')
);
const NormalDistributionCalculator = React.lazy(() =>
  import('../components/NormalDistributionCalculator')
);
const BayesianProbabilityCalculator = React.lazy(() =>
  import('../components/BayesianProbabilityCalculator')
);
const PermutationsCalculator = React.lazy(() =>
  import('../components/PermutationsCalculator')
);
const CombinationsCalculator = React.lazy(() =>
  import('../components/CombinationsCalculator')
);
const ExpectedValueCalculator = React.lazy(() =>
  import('../components/ExpectedValueCalculator')
);
const VarianceCalculator = React.lazy(() =>
  import('../components/VarianceCalculator')
);
const StandardDeviationCalculator = React.lazy(() =>
  import('../components/StandardDeviationCalculator')
);
const ZScoreCalculator = React.lazy(() =>
  import('../components/ZScoreCalculator')
);
const ConfidenceIntervalCalculator = React.lazy(() =>
  import('../components/ConfidenceIntervalCalculator')
);
const HypergeometricCalculator = React.lazy(() =>
  import('../components/HypergeometricCalculator')
);
const PoissonDistributionCalculator = React.lazy(() =>
  import('../components/PoissonDistributionCalculator')
);

const TexasHoldemCalculator = React.lazy(() =>
  import('../components/TexasHoldemCalculator')
);

const BlackjackProbabilityCalculator = React.lazy(() =>
  import('../components/BlackjackProbabilityCalculator')
);

const calculators = [
  {
    name: 'Single Event Probability Calculator',
    path: 'single-event',
    component: SingleEventProbabilityCalculator,
  },
  {
    name: 'Combined Probability Calculator',
    path: 'combined-probability',
    component: CombinedProbabilityCalculator,
  },
  {
    name: 'Conditional Probability Calculator',
    path: 'conditional-probability',
    component: ConditionalProbabilityCalculator,
  },
  {
    name: 'Binomial Probability Calculator',
    path: 'binomial-probability',
    component: BinomialProbabilityCalculator,
  },
  {
    name: 'Normal Distribution PDF Calculator',
    path: 'normal-distribution',
    component: NormalDistributionCalculator,
  },
  {
    name: 'Bayesian Probability Calculator',
    path: 'bayesian-probability',
    component: BayesianProbabilityCalculator,
  },
  {
    name: 'Permutations Calculator',
    path: 'permutations',
    component: PermutationsCalculator,
  },
  {
    name: 'Combinations Calculator',
    path: 'combinations',
    component: CombinationsCalculator,
  },
  {
    name: 'Expected Value Calculator',
    path: 'expected-value',
    component: ExpectedValueCalculator,
  },
  {
    name: 'Variance Calculator',
    path: 'variance',
    component: VarianceCalculator,
  },
  {
    name: 'Standard Deviation Calculator',
    path: 'standard-deviation',
    component: StandardDeviationCalculator,
  },
  {
    name: 'Z-Score Calculator',
    path: 'z-score',
    component: ZScoreCalculator,
  },
  {
    name: 'Confidence Interval Calculator',
    path: 'confidence-interval',
    component: ConfidenceIntervalCalculator,
  },
  {
    name: 'Hypergeometric Distribution Calculator',
    path: 'hypergeometric',
    component: HypergeometricCalculator,
  },
  {
    name: 'Poisson Distribution Calculator',
    path: 'poisson-distribution',
    component: PoissonDistributionCalculator,
  },
  {
    name: 'Texas Hold\'em Probability Calculator',
    path: 'texas-holdem',
    component: TexasHoldemCalculator,
  },
  {
    name: 'Blackjack Probability Calculator',
    path: 'blackjack-probability',
    component: BlackjackProbabilityCalculator,
  },
];

export default calculators;
