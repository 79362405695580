import React from 'react';
import { Link } from 'react-router-dom';
import calculators from '../data/calculators';

function HomePage() {
  return (
    <div className="space-y-12">
      {/* Hero Section */}
      <div className="bg-dark-surface py-28 px-4 sm:px-6 lg:px-8 shadow-md">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-5xl font-extrabold text-white mb-6">
            Probability Calculators
          </h1>
          <p className="text-2xl text-white text-opacity-80">
            Explore and calculate various probability concepts with our easy-to-use tools
          </p>
        </div>
      </div>

      {/* Calculators List */}
      <div className="px-4 sm:px-6 lg:px-8">
        <h2 className="text-2xl font-bold text-dark-primary mb-6">Select a calculator:</h2>
        <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {calculators.map((calculator) => (
            <li key={calculator.path} className="bg-dark-surface shadow rounded-lg p-4 hover:shadow-md transition-shadow duration-200">
              <Link to={`/${calculator.path}`} className="text-dark-primary hover:text-blue-400 font-semibold">
                {calculator.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default HomePage;