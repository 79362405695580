// src/pages/CalculatorPage.jsx

import React, { Suspense } from 'react';
import { Link, useParams } from 'react-router-dom';
import calculators from '../data/calculators';

function CalculatorPage() {
  const { calculatorPath } = useParams();
  const calculator = calculators.find((calc) => calc.path === calculatorPath);

  if (!calculator) {
    return (
      <div className="text-center">
        <h2 className="text-2xl font-bold text-red-600 mb-4">Calculator Not Found</h2>
        <Link to="/" className="text-dark-primary hover:text-blue-400">Back to Home</Link>
      </div>
    );
  }

  const CalculatorComponent = calculator.component;

  return (
    <div className="space-y-6 p-4 md:p-6 lg:p-8">
      <h2 className="text-2xl font-bold text-dark-primary mb-6 pb-2 border-b border-gray-700">
        {calculator.name}
      </h2>
      <Suspense fallback={<div className="text-center text-dark-text-secondary">Loading...</div>}>
        <CalculatorComponent />
      </Suspense>
      <div className="mt-8">
        <Link to="/" className="text-dark-primary hover:text-blue-400">Back to Home</Link>
      </div>
    </div>
  );
}

export default CalculatorPage; // Ensure this line is present
