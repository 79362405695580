import React from 'react';
import { Link } from 'react-router-dom';
import blogPosts from '../data/blogPosts';

function BlogList() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-dark-primary mb-6">Probability Blog</h1>
      {blogPosts.length === 0 ? (
        <p>No blog posts found.</p>
      ) : (
        <div className="space-y-6">
          {blogPosts.map((post) => (
            <article key={post.slug} className="bg-dark-surface shadow-md rounded-lg p-6 hover:shadow-lg transition-shadow duration-200">
              <Link to={`/blog/${post.slug}`} className="block">
                <h2 className="text-2xl font-semibold text-dark-primary hover:text-blue-400 mb-2">{post.title}</h2>
                <p className="text-dark-text-secondary mb-4">{post.excerpt}</p>
                <div className="flex justify-between items-center text-sm text-dark-text-secondary">
                  <span>Published on: {post.date}</span>
                  <span className="text-dark-primary hover:underline">Read more &rarr;</span>
                </div>
              </Link>
            </article>
          ))}
        </div>
      )}
    </div>
  );
}

export default BlogList;