import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import HomePage from './pages/HomePage';
import CalculatorPage from './pages/CalculatorPage';
import BlogList from './components/BlogList';
import BlogPost from './components/BlogPost';
import AboutPage from './pages/AboutPage';

function App() {
  return (
    <div className="bg-dark-background min-h-screen">
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/:calculatorPath" element={<CalculatorPage />} />
            <Route path="/blog" element={<BlogList />} />
            <Route path="/blog/:slug" element={<BlogPost />} />
            <Route path="/about" element={<AboutPage />} />
          </Routes>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
